.herosection {
  height: 92vh;
  background-size: cover;
  background-position: center;
  display: flex;
  background-image: url("../../../public/assets/vancouver.jpg");
  overflow-wrap: break-word;
  word-break: break-word;
}

.herosection-contents {
  background-color: rgba(0, 0, 0, 0.65);
  margin-left: 10%;
  margin-top: 5%;
  height: 30rem;
  width: 55rem;
  text-align: left;
}

.intro {
  margin-top: 9%;
  margin-bottom: 5%;
  margin-left: 9%;
  margin-right: 9%;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 75px;
  color: white;
}

.bottom-section {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
  gap: 36px;
}

button {
  font-size: max(1vw, 15px);
  color: white;
  font-weight: 500;
  padding: 0.6vw 1.2vw;
  background-color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid black;
}

button:hover {
  background-color: white;
  color: black;
}

.hero-btn-main {
  font-weight: 400;
  color: #fff;
  background-color: #d71827;
  border: #d71827 1px solid;
  transition: background-color 0.3s ease;
}

.hero-btn:hover {
  color: white;
  background-color: black;
  border: #ffffff 1px solid;
}

/* iPad-specific styles */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .herosection {
    justify-content: center;
    align-items: center;
  }

  .herosection-contents {
    width: 80%;
    margin: 0;
    max-width: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }

  .intro {
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .title {
    font-size: 55px;
    line-height: 60px;
    margin-bottom: 2rem;
  }

  .bottom-section {
    margin-top: 2rem;
    gap: 20px;
  }

  .hero-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .title {
    font-size: 50px;
    line-height: 55px;
  }
  .herosection-contents {
    width: 45%;
    height: 55%;
  }
}

.herosection .bottom-section .hero-btn-secondary {
  background-color: black;
  border: #ffffff 1px solid;
}
.herosection .bottom-section .hero-btn-secondary:hover {
  background-color: #d71827;
  border: black 1px solid;
}

@media only screen and (max-width: 768px) {
  .herosection {
    height: 42rem;
    background-image: url("../../../public/assets/vancouver-vertical.jpg");
  }
  .herosection-contents {
    margin: 6rem auto;
    width: 20rem;
    height: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    padding: 0 10px;
  }
  .bottom-section {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .intro {
    margin: 0;
  }
  .bottom-section .hero-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
