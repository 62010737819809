.testimonial-container {
  height: 45vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border: 8px solid #c8102e;
  margin: 0px 100px 120px 100px;
}

.icon-quote-left {
  position: relative;
  transform: translateY(50%);
  font-size: 70px;
  background-color: white;
  color: #c8102e;
  padding: 0px 5px;
}

.previous-handler {
  font-size: 20px;
  margin-left: 30px;
  margin-top: 20vh;
  color: #c8102e;
}

.previous-handler:hover,
.next-handler:hover {
  cursor: pointer;
  color: black;
}

.testimonial {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.quote {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
}

.break-line {
  font-size: 1.5rem;
  font-weight: bold;
}

.clientName {
  font-size: 1.3rem;
  font-weight: 700;
  color: #333;
}

.clientTitle {
  font-style: italic;
}

.clientTitleAndCompany {
  display: flex;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.next-handler {
  font-size: 20px;
  margin-top: 20vh;
  margin-right: 30px;
  color: #c8102e;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .testimonial-container {
    margin: 0px 50px 80px 50px;
    height: 35vh;
  }

  .icon-quote-left {
    font-size: 60px;
    transform: translateY(50%);
  }

  .quote {
    font-size: 1.5rem;
  }

  .clientName {
    font-size: 1.2rem;
  }

  .clientTitleAndCompany {
    font-size: 1rem;
  }

  .previous-handler,
  .next-handler {
    margin-top: 15vh;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 767px) {
  .testimonial-container {
    margin: 20px auto 40px auto;
    height: 520px;
    padding: 20px 0;
    width: 90%;
  }

  .testimonial-container .testimonial .quote {
    font-size: 22px;
  }

  .next-handler,
  .previous-handler {
    margin: auto 8px;
  }

  .testimonial-container .testimonial {
    padding: 0;
  }

  .clientName {
    font-size: 1.1rem;
  }

  .clientTitleAndCompany {
    font-size: 0.9rem;
  }

  .icon-quote-left {
    font-size: 60px;
    padding: 10px 20px;
    transform: translateY(60%);
  }
}
