.navbar {
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  height: 8vh;
}

.navbar .logo {
  width: 50px;
}

.navbar .navbar-title {
  color: #fff;
  font-size: 25px;
  margin-left: 20px;
}

.navbar .navbar-left {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar .navbar-menu {
  display: flex;
  list-style: none;
  gap: 20px;
  color: #fff;
  font-size: 18px;
  align-items: center;
}

.navbar .active {
  padding-bottom: 2px;
  border-bottom: 2px solid #49557e;
}

.navbar li {
  cursor: pointer;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .navbar {
    padding: 10px 20px;
  }

  .navbar .navbar-title {
    font-size: 20px;
  }

  .navbar .navbar-menu {
    font-size: 16px;
    gap: 15px;
  }

  .navbar-search {
    width: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 10px;
    height: auto;
    flex-wrap: wrap;
  }

  .navbar .navbar-left {
    margin-left: 0;
    padding: 0;
  }

  .navbar-left .navbar-title {
    display: none;
  }

  .navbar-right {
    width: 100%;
    justify-content: flex-end;
  }

  .menu-toggle {
    display: block;
  }

  .navbar .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: black;
    padding: 10px 0;
  }

  .navbar .navbar-menu.open {
    display: flex;
  }

  .navbar-search {
    width: 100%;
    margin-bottom: 10px;
  }
}
