.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../public/assets/neu-N.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 80vh;
  padding: 30px;
  box-sizing: border-box;
}

.about-info {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.about-content {
  text-align: left;
  font-size: 1rem;
}

.about-content p {
  margin-bottom: 1rem;
}

.button-container {
  margin-top: 2rem;
}

.about-btn {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .about-container {
    background-size: 80% auto;
    padding: 20px;
  }

  .about-info {
    max-width: 100%;
  }

  .about-us {
    font-size: 1.5rem;
  }

  .about-content {
    font-size: 0.9rem;
  }

  button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .about-container {
    background-size: 100% auto;
  }

  .about-us {
    font-size: 1.3rem;
  }

  .about-content {
    font-size: 0.8rem;
  }

  button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
