.single-project-title-container {
  background-color: #d71827;
  margin-top: 20px;
  padding: 20px 0;
}

.single-project-title {
  font-weight: bold;
  color: #fff;
  font-size: 2.3em;
  text-align: left;
  padding: 0 0 0 150px;
}

.single-project-container {
  display: flex;
  justify-content: space-between;
  margin: 0 150px;
  gap: 50px;
}

.single-project-texts-sections,
.single-project-image-video-section {
  width: 50%;
  margin-bottom: 100px;
}

.single-project-section {
  font-weight: bold;
  color: #d71827;
  font-size: 1.2em;
  margin-top: 30px;
  text-align: left;
}

.single-project-tags {
  display: flex;
  padding: 5px 0 0 150px;
}

.single-tag {
  margin-right: 10px;
  color: white;
}

.single-project-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.single-project-info {
  font-size: 1em;
  color: #555;
  margin: 0;
  text-align: left;
}

.single-project-poster {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: zoom-in;
}

.single-project-video {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.single-project-video iframe {
  width: 100%;
  height: 100%;
}

/* Modal styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  cursor: zoom-out;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.modal-content img {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

/* Responsive styles */
@media screen and (max-width: 1024px) {
  .single-project-container {
    margin: 0 50px;
    gap: 30px;
  }

  .single-project-title {
    padding: 0 0 0 50px;
    font-size: 2em;
  }

  .single-project-tags {
    padding: 5px 0 0 50px;
  }
}

/* iPad-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .single-project-container {
    flex-direction: column;
    align-items: center;
  }

  .single-project-texts-sections,
  .single-project-image-video-section {
    width: 80%;
    margin-bottom: 50px;
  }

  .single-project-title {
    font-size: 2.2em;
    padding: 0 0 0 40px;
  }

  .single-project-tags {
    padding: 5px 0 0 40px;
    flex-wrap: wrap;
  }

  .single-project-section {
    font-size: 1.1em;
  }

  .single-project-info {
    font-size: 0.95em;
  }
}

@media screen and (max-width: 768px) {
  .single-project-container {
    flex-direction: column;
    margin: 0 20px;
  }

  .single-project-texts-sections,
  .single-project-image-video-section {
    width: 100%;
    margin-bottom: 50px;
  }

  .single-project-title {
    padding: 0 20px;
    font-size: 1.8em;
  }

  .single-project-tags {
    padding: 5px 20px;
    flex-wrap: wrap;
  }

  .single-project-section {
    font-size: 1.1em;
  }

  .single-project-list {
    gap: 3px;
  }
}

@media screen and (max-width: 480px) {
  .single-project-title {
    font-size: 1.5em;
  }

  .single-project-section {
    font-size: 1em;
  }

  .single-project-info {
    font-size: 0.9em;
  }
}
