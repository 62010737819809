.about-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background-size: cover;
  background-position: center;
  background-image: url("../../../public/assets/neu-building.jpg");
}

.hook {
  font-size: 45px;
  color: white;
  text-align: center;
  font-weight: 500;
  padding: 20px;
  max-width: 90%;
}

/* iPad-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-header {
    height: 35vh;
  }

  .hook {
    font-size: 38px;
    max-width: 80%;
  }
}

/* Tablet and smaller screens */
@media screen and (max-width: 768px) {
  .about-header {
    height: 30vh;
  }

  .hook {
    font-size: 30px;
  }
}

/* Mobile screens */
@media screen and (max-width: 480px) {
  .about-header {
    height: 25vh;
  }

  .hook {
    font-size: 24px;
  }
}
