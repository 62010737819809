.section-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 20px;
}

.preview-projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 30px;
  padding: 0 20px;
}

.preview-header {
  font-size: 250%;
  text-align: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
}

.project {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.project img {
  aspect-ratio: 16/10;
  object-fit: cover;
  width: 100%;
}

.project:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.project-info {
  margin: 10px 25px;
  text-align: left;
}

.project-name {
  margin-bottom: 10px;
}

.button-container {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .preview-projects {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;
  }

  .preview-header {
    font-size: 200%;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .button-container {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .preview-projects {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .preview-header {
    font-size: 180%;
  }

  .section-container {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 640px) {
  .section-container {
    display: none;
  }
}
