.developers-container {
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
  padding: 2rem;
  margin-bottom: 0.5rem;
}

.developers-title {
  color: white;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.developers-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
}

.developer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(25% - 2rem);
  min-width: 200px;
  margin-bottom: 2rem;
}

.developer-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.developer-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.developer-name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.developer-program {
  font-size: 0.9rem;
  font-style: italic;
  color: #ccc;
}

.linkedin-logo {
  width: 24px;
  height: 24px;
}

/* iPad-specific styles */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .developers-container {
    padding: 2.5rem;
  }

  .developers-title {
    font-size: 2.2rem;
    margin-bottom: 2.5rem;
  }

  .developers-info-container {
    gap: 2.5rem;
  }

  .developer-info {
    width: calc(33.33% - 2.5rem);
    min-width: 220px;
  }

  .developer-image {
    width: 140px;
    height: 140px;
  }

  .developer-name {
    font-size: 1.3rem;
  }

  .developer-program {
    font-size: 1rem;
  }

  .linkedin-logo {
    width: 28px;
    height: 28px;
  }
}

/* Tablet and smaller screens */
@media screen and (max-width: 768px) {
  .developers-title {
    font-size: 1.75rem;
  }

  .developer-info {
    width: calc(33.33% - 2rem);
  }

  .developer-image {
    width: 100px;
    height: 100px;
  }

  .developer-name {
    font-size: 1.1rem;
  }

  .developer-program {
    font-size: 0.8rem;
  }
}

/* Mobile screens */
@media screen and (max-width: 480px) {
  .developers-container {
    padding: 1.5rem;
  }

  .developers-title {
    font-size: 1.5rem;
  }

  .developers-info-container {
    gap: 1rem;
  }

  .developer-info {
    width: calc(50% - 1rem);
    min-width: 150px;
  }

  .developer-image {
    width: 80px;
    height: 80px;
  }

  .developer-name {
    font-size: 1rem;
  }

  .developer-program {
    font-size: 0.75rem;
  }

  .linkedin-logo {
    width: 20px;
    height: 20px;
  }
}
